import React, {useState} from "react";
import { CSSTransition } from "react-transition-group";
import JunioresA from "./img/jun_fem_a.png";
import './MenusFormacao.css';

const MenuJunioresA= () => {

  const [open, setOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState("main");
  const [menuHeight, setMenuHeight] = useState(null);
  const [menuVisible, setMenuVisible] = useState(false);

  // Helper function to calculate the height of the menu
  const calcHeight = (el) => {
    const height = el.scrollHeight;
    setMenuHeight(height);
  };

  // Event handler for clicking a menu item
  const handleMenuClick = (menu) => {
    setOpen(!open);
    setActiveMenu(menu);
  };

  // Event handler for clicking the logo
  const handleLogoClick = () => {
    if (open) {
      setOpen(false);
      setActiveMenu("main");
      setMenuHeight(null);
      setMenuVisible(false);
    } else {
      setOpen(true);
      setMenuVisible(true);
    }
  };

  return (
    <div className="responsive-menu">
      <img
        src={JunioresA}
        alt="Logo"
        onClick={handleLogoClick}
        className="w-64 h-30 object-full"
      />

      {menuVisible && (
        <div className="dropdown">

          <CSSTransition
            in={open && activeMenu === "main"}
            timeout={500}
            classNames="menu-primary"
            unmountOnExit 
            onEnter={calcHeight}
          >
            <div className="menu-primary">
            <DropdownItem
                label="1º Fase"
                setActiveMenu={setActiveMenu}
                 goToMenu = "NJA1F_1Fase"
              />
              <DropdownItem
                label="2º Fase"
                setActiveMenu={setActiveMenu}
                goToMenu = "NJA1F_2Fase"
              />
            </div>
          </CSSTransition>

          <CSSTransition
            in={open && activeMenu === "NJA1F_1Fase"}
            timeout={500}
            classNames="menu-primary"
            unmountOnExit 
            onEnter={calcHeight}
          >
            <div className="menu-primary">
            <DropdownItem 
              goToMenu="main" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
            <DropdownItem
                campeonato="NJA1F"
                fase="1"
                serie="N"
                label="Campeonato Nacional Juniores A - 1º Fase - Norte"
                setActiveMenu={setActiveMenu}
                onSubmit="true"
              />
              <DropdownItem
                campeonato="NJA1F"
                fase="1"
                serie="S"
                label="Campeonato Nacional Juniores A - 1º Fase - Sul"
                setActiveMenu={setActiveMenu}
                onSubmit="true"
              />
            </div>
          </CSSTransition>

          <CSSTransition
            in={open && activeMenu === "NJA1F_2Fase"}
            timeout={500}
            classNames="menu-primary"
            unmountOnExit 
            onEnter={calcHeight}
          >
            <div className="menu-primary">
            <DropdownItem 
              goToMenu="main" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
            <DropdownItem
                label="Série Primeiros"
                setActiveMenu={setActiveMenu}
                 goToMenu = "NJA1F_SP"
              />
              <DropdownItem
                label="Série Últimos"
                setActiveMenu={setActiveMenu}
                goToMenu = "NJA1F_SU"
              />
            </div>
          </CSSTransition>
          <CSSTransition
            in={open && activeMenu === "NJA1F_SP"}
            timeout={500}
            classNames="menu-primary"
            unmountOnExit 
            onEnter={calcHeight}
          >
            <div className="menu-primary">
            <DropdownItem 
              goToMenu="NJA1F_2Fase" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
            <DropdownItem
                campeonato="NJA1F"
                fase="2"
                serie="PN"
                label="Série Norte"
                setActiveMenu={setActiveMenu}
                onSubmit="true"
              />
              <DropdownItem
                campeonato="NJA1F"
                fase="2"
                serie="PS"
                label="Série Sul"
                setActiveMenu={setActiveMenu}
                onSubmit="true"
              />
            </div>
          </CSSTransition>
          <CSSTransition
            in={open && activeMenu === "NJA1F_SU"}
            timeout={500}
            classNames="menu-primary"
            unmountOnExit 
            onEnter={calcHeight}
          >
            <div className="menu-primary">
            <DropdownItem 
              goToMenu="NJA1F_2Fase" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />s
            <DropdownItem
                campeonato="NJA1F"
                fase="2"
                serie="UN"
                label="Série Norte"
                setActiveMenu={setActiveMenu}
                onSubmit="true"
              />
              <DropdownItem
                campeonato="NJA1F"
                fase="2"
                serie="US"
                label="Série Sul"
                setActiveMenu={setActiveMenu}
                onSubmit="true"
              />
            </div>
          </CSSTransition>

          </div>
      )}
    </div>
  );
};

const DropdownItem = ({ campeonato, fase, serie, label, goToMenu, setActiveMenu, onSubmit }) => {
  const buttonRef = React.useRef(null);

  const handleClick = () => {
    if (goToMenu) {
      setActiveMenu(goToMenu);
    } else if (onSubmit) {
      const selector = `#${campeonato}-${fase}-${serie}`.replace(/[^a-zA-Z0-9-_]/g, '-');
      const form = document.querySelector(selector);
      if (form) {
        form.submit();
      }
    }
  };

  return (
    <div className="menu-item" onClick={handleClick}>
      {onSubmit ? (
        <form
          onSubmit={(e) => {
          }}
          action="https://www.portugalvoleibol.com/classificacao/classificacoes_react"
          method="POST"
        >
          <input type="hidden" name="campeonato" value={campeonato} />
          <input type="hidden" name="fase" value={fase} />
          <input type="hidden" name="serie" value={serie} />
          <button type="submit" ref={buttonRef}>
            {label}
          </button>
        </form>
      ) : (
        <button type="button" ref={buttonRef}>
          {label}
        </button>
      )}
    </div>
  );
};

export default MenuJunioresA;