import React, {useState} from "react";
import { CSSTransition } from "react-transition-group";
import Iniciados from "./img/2025_iniciados.png";
import './MenusFormacao.css';

const MenuIniciados = () => {
  const [open, setOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState("main");
  const [menuHeight, setMenuHeight] = useState(null);
  const [menuVisible, setMenuVisible] = useState(false);

  // Helper function to calculate the height of the menu
  const calcHeight = (el) => {
    const height = el.scrollHeight;
    setMenuHeight(height);
  };

  // Event handler for clicking a menu item
  const handleMenuClick = (menu) => {
    setOpen(!open);
    setActiveMenu(menu);
  };

  // Event handler for clicking the logo
  const handleLogoClick = () => {
    if (open) {
      setOpen(false);
      setActiveMenu("main");
      setMenuHeight(null);
      setMenuVisible(false);
    } else {
      setOpen(true);
      setMenuVisible(true);
    }
  };

  return (
    <div className="responsive-menu">
      <img
        src={Iniciados}
        alt="Logo"
        onClick={handleLogoClick}
        className="w-64 h-30 object-full"
      />

      {menuVisible && (
        <div className="dropdown">
          <CSSTransition
            in={open && activeMenu === "main"}
            timeout={500}
            classNames="menu-primary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu-primary">
            <DropdownItem
              label="Masculino"
              goToMenu = "masculino"
              setActiveMenu={setActiveMenu}
            />
               <DropdownItem
              label="Feminino"
              goToMenu = "feminino"
              setActiveMenu={setActiveMenu}
            />
            </div>
          </CSSTransition>

          {/* Menu Iniciados Masculino */}

          <CSSTransition
            in={activeMenu === "masculino"}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="main" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
              goToMenu="NINIM_1Fase" 
              label="1º Fase"
              setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
              goToMenu="NINIM_2Fase" 
              label="2º Fase"
              setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
              goToMenu="NINIM_Final" 
              label="Fase Final"
              setActiveMenu={setActiveMenu}
              />
            </div>
          </CSSTransition>

          {/* Menu Iniciados Masculino - 1ªFase */}

          <CSSTransition
            in={activeMenu === "NINIM_1Fase"}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="feminino" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
              {/*<DropdownItem 
                campeonato="NINIM"
                fase="1"
                serie="A"
                label="ASSOCIAÇÃO DE VOLEIBOL DO ALENTEJO E ALGARVE"
                subtipo="AVAL"
                onSubmit="true"
               setActiveMenu={setActiveMenu}
               />
              <DropdownItem 
                 campeonato="NINIM"
                 fase="1"
                 serie="A"
                 label="ASSOCIAÇÃO DE VOLEIBOL DE BRAGA"
                 subtipo="AVB"
                 onSubmit="true"
                 setActiveMenu={setActiveMenu}
              />*/}
              <DropdownItem 
                  campeonato="NINIM"
                  fase="1"
                  serie="A"
                  label="ASSOCIAÇÃO DE VOLEIBOL DE COIMBRA"
                  subtipo="AVC"
                  onSubmit="true"
                  setActiveMenu={setActiveMenu}
              />
                 {/*<DropdownItem 
                  campeonato="NINIM"
                  fase="1"
                  serie="A"
                  label="ASSOCIAÇÃO DE DESPORTOS DA ILHA DO FAIAL"
                  subtipo="AVC"
                  onSubmit="true"
                  setActiveMenu={setActiveMenu}
                  />
              <DropdownItem 
                  campeonato="NINIM"
                  fase="1"
                  serie="A"
                  label="ASSOCIAÇÃO DE VOLEIBOL DA GUARDA"
                  subtipo="AVG"
                  onSubmit="true"
                  setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NINIM"
                fase="1"
                serie="A"
                label="ASSOCIAÇÃO DE VOLEIBOL DE LEIRIA"
                subtipo="ADL"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />*/}
              <DropdownItem 
                campeonato="NINIM"
                fase="1"
                serie="A"
                label="ASSOCIAÇÃO DE VOLEIBOL DE LISBOA"
                subtipo="AVL"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
                  {/*<DropdownItem 
                  goToMenu="NINIM_AV_Pico" 
                  label="ASSOCIAÇÃO DE VOLEIBOL DA ILHA DO PICO"
                  setActiveMenu={setActiveMenu}
                    />*/}
               <DropdownItem 
                  goToMenu="NINIM_AVP" 
                  label="ASSOCIAÇÃO DE VOLEIBOL DO PORTO"
                  setActiveMenu={setActiveMenu}
              />
                  {/*<DropdownItem 
                  goToMenu="NINIM_AV_SantaMaria" 
                  label="ASSOCIAÇÃO DE VOLEIBOL DA ILHA SANTA MARIA"
                  setActiveMenu={setActiveMenu}
                  />
                  <DropdownItem 
                  goToMenu="NINIM_AV_Terceira" 
                  label="ASSOCIAÇÃO DE VOLEIBOL DA ILHA TERCEIRA"
                  setActiveMenu={setActiveMenu}
                  />
              <DropdownItem 
                  campeonato="NINIM"
                  fase="1"
                  serie="A"
                  label="ASSOCIAÇÃO DE VOLEIBOL DE VIANA DE CASTELO"
                  subtipo="AVVC"
                  onSubmit="true"
                  setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NINIM"
                fase="1"
                serie="A"
                label="ASSOCIAÇÃO DE VOLEIBOL DE VISEU"
                subtipo="AVV"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
                  {/*<DropdownItem 
                  goToMenu="NINIM_AV_Flores" 
                  label="ASSOCIAÇÃO DE VOLEIBOL DA ILHA DAS FLORES"
                  setActiveMenu={setActiveMenu}
                  />
              <DropdownItem 
                campeonato="NINIM"
                fase="1"
                serie="A"
                label="ASSOCIAÇÃO DE VOLEIBOL TRÁS-OS-MONTES"
                subtipo="AVTM"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />*/}
            </div>
          </CSSTransition>

           {/* Menu Iniciados Masculino - AVP*/}

           <CSSTransition
                in={activeMenu === "NINIM_AVP"}
                timeout={500}
                classNames="menu-secondary"
                unmountOnExit
                onEnter={calcHeight}
          >
          <div className="menu">
          <DropdownItem 
              goToMenu="NINIM_1Fase" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
                  <DropdownItem
              label="1ª Fase"
              goToMenu = "NINIM_AVP_1Fase"
              setActiveMenu={setActiveMenu}
            />
               <DropdownItem
              label="2ª Fase"
              goToMenu = "NINIM_AVP_2Fase"
              setActiveMenu={setActiveMenu}
            />
            </div>
          </CSSTransition>

 {/* Menu Iniciados Masculino - 1ªFase - AVP */}

 <CSSTransition
                in={activeMenu === "NINIM_AVP_1Fase"}
                timeout={500}
                classNames="menu-secondary"
                unmountOnExit
                onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="NINIM_AVP" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
               <DropdownItem 
                campeonato="NINIM"
                fase="1"
                serie="A"
                subtipo="AVP"
                label="Série A"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NINIM"
                fase="1"
                serie="B"
                subtipo="AVP"
                label="Série B"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
            </div>
          </CSSTransition> 
          

          {/* Menu Iniciados Masculino - 1ªFase - AVP - 2º Fase*/}

          <CSSTransition
                in={activeMenu === "NINIM_AVP_2Fase"}
                timeout={500}
                classNames="menu-secondary"
                unmountOnExit
                onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="NINIM_AVP" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
               <DropdownItem 
                campeonato="NINIM"
                fase="1"
                serie="SA"
                subtipo="AVP"
                label="Série A"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NINIM"
                fase="1"
                serie="SB"
                subtipo="AVP"
                label="Série B"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
            </div>
          </CSSTransition> 

          {/* Menu Iniciados Masculino - 2ªFase */}

          <CSSTransition
            in={activeMenu === "NINIM_2Fase"}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="main" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
  campeonato="NINIM"
  fase="2"
  serie="SA"
  label="Série A"
  onSubmit="true"
  setActiveMenu={setActiveMenu}
  formAction={true}
/>
<DropdownItem 
  campeonato="NINIM"
  fase="2"
  serie="SB"
  label="Série B"
  onSubmit="true"
  setActiveMenu={setActiveMenu}
  formAction={true}
/>
<DropdownItem 
  campeonato="NINIM"
  fase="2"
  serie="SC"
  label="Série C"
  onSubmit="true"
  setActiveMenu={setActiveMenu}
  formAction={true}
/>
<DropdownItem 
  campeonato="NINIM"
  fase="2"
  serie="SD"
  label="Série D"
  onSubmit="true"
  setActiveMenu={setActiveMenu}
  formAction={true}
/>

            </div>
          </CSSTransition>

          {/* Menu Iniciados Masculino - Fase Final */}

          <CSSTransition
            in={activeMenu === "NINIM_Final"}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="main" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
              <DropdownItem
                campeonato="NINIM"
                fase="F"
                serie="F"
                label="Apuramento do Campeão Nacional"
                onSubmit="true"
              />
            </div>
          </CSSTransition>

          {/* Menu Iniciados Feminino */}

          <CSSTransition
            in={activeMenu === "feminino"}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="main" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
              goToMenu="NINIF_1Fase" 
              label="1º Fase"
              setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
              goToMenu="NINIF_2Fase" 
              label="2º Fase"
              setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
              goToMenu="NINIF_Final" 
              label="Fase Final"
              setActiveMenu={setActiveMenu}
              />
            </div>
          </CSSTransition>

          {/* Menu Iniciados Feminino - 1ªFase */}

          <CSSTransition
            in={activeMenu === "NINIF_1Fase"}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="feminino" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                goToMenu="NINIF_AVAL"
                label="ASSOCIAÇÃO DE VOLEIBOL DO ALENTEJO E ALGARVE"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                 goToMenu="NINIF_AVB"
                 label="ASSOCIAÇÃO DE VOLEIBOL DE BRAGA"
                 setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                  campeonato="NINIF"
                  fase="1"
                  serie="A"
                  label="ASSOCIAÇÃO DE VOLEIBOL DE COIMBRA"
                  subtipo="AVC"
                  onSubmit="true"
                  setActiveMenu={setActiveMenu}
              />
                  {/*<DropdownItem 
                  campeonato="NINIF"
                  fase="1"
                  serie="A"
                  label="ASSOCIAÇÃO DE DESPORTOS DA ILHA DO FAIAL"
                  subtipo="AVC"
                  onSubmit="true"
                  setActiveMenu={setActiveMenu}
                  />
              <DropdownItem 
                  campeonato="NINIF"
                  fase="1"
                  serie="A"
                  label="ASSOCIAÇÃO DE VOLEIBOL DA GUARDA"
                  subtipo="AVG"
                  onSubmit="true"
                  setActiveMenu={setActiveMenu}
              />*/}
              <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="A"
                label="ASSOCIAÇÃO DE VOLEIBOL DE LEIRIA"
                subtipo="ADL"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                goToMenu="NINIF_AVL" 
                label="ASSOCIAÇÃO DE VOLEIBOL DE LISBOA"
                setActiveMenu={setActiveMenu}
              />
                  {/*<DropdownItem 
                  goToMenu="NINIF_AV_Pico" 
                  label="ASSOCIAÇÃO DE VOLEIBOL DA ILHA DO PICO"
                  setActiveMenu={setActiveMenu}
                    />*/}
              <DropdownItem 
                  goToMenu="NINIF_AVP" 
                  label="ASSOCIAÇÃO DE VOLEIBOL DO PORTO"
                  setActiveMenu={setActiveMenu}
              />
                  {/*<DropdownItem 
                  goToMenu="NINIF_AV_SantaMaria" 
                  label="ASSOCIAÇÃO DE VOLEIBOL DA ILHA SANTA MARIA"
                  setActiveMenu={setActiveMenu}
                  />
                  <DropdownItem 
                  goToMenu="NINIF_AV_Terceira" 
                  label="ASSOCIAÇÃO DE VOLEIBOL DA ILHA TERCEIRA"
                  setActiveMenu={setActiveMenu}
                  />
              <DropdownItem 
                  campeonato="NINIF"
                  fase="1"
                  serie="A"
                  label="ASSOCIAÇÃO DE VOLEIBOL DE VIANA DE CASTELO"
                  subtipo="AVVC"
                  onSubmit="true"
                  setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="A"
                label="ASSOCIAÇÃO DE VOLEIBOL DE VISEU"
                subtipo="AVV"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
                  {/*<DropdownItem 
                  goToMenu="NINIF_AV_Flores" 
                  label="ASSOCIAÇÃO DE VOLEIBOL DA ILHA DAS FLORES"
                  setActiveMenu={setActiveMenu}
                  />*/}
               <DropdownItem 
                  goToMenu="NINIF_AVTM" 
                  label="ASSOCIAÇÃO DE VOLEIBOL TRÁS-OS-MONTES"
                  setActiveMenu={setActiveMenu}
              />
            </div>
          </CSSTransition>

           {/* Menu Iniciados Feminino - 1ºFase - AVP*/}

           <CSSTransition
                in={activeMenu === "NINIF_AVP"}
                timeout={500}
                classNames="menu-secondary"
                unmountOnExit
                onEnter={calcHeight}
          >
          <div className="menu">
          <DropdownItem 
              goToMenu="NINIF_1Fase" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
            <DropdownItem
              label="1ª Fase"
              goToMenu = "NINIF_AVP_1Fase"
              setActiveMenu={setActiveMenu}
            />
               <DropdownItem
              label="2ª Fase"
              goToMenu = "NINIF_AVP_2Fase"
              setActiveMenu={setActiveMenu}
            /> {/* 
            <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="PO"
                label="Play-Off De Acesso Ao Nacional"
                subtipo="AVP"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />*/}
            </div>
          </CSSTransition>

            {/* Menu Iniciados Feminino - 1ªFase - AVP */}

            <CSSTransition
            in={activeMenu === "NINIF_AVP_1Fase"}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="NINIM_1Fase" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
               <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="A"
                subtipo="AVP"
                label="Série A"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="B"
                subtipo="AVP"
                label="Série B"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="C"
                subtipo="AVP"
                label="Série C"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="D"
                subtipo="AVP"
                label="Série D"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="E"
                subtipo="AVP"
                label="Série E"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="F"
                subtipo="AVP"
                label="Série F"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
                <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="AB"
                subtipo="AVP"
                label="Série AB"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
            </div>
          </CSSTransition> 

          {/* Menu Iniciados Feminino - 1ªFase - AVP - 2º Fase*/}

          <CSSTransition
                in={activeMenu === "NINIF_AVP_2Fase"}
                timeout={500}
                classNames="menu-secondary"
                unmountOnExit
                onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="NINIF_AVP" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
               <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="SA"
                subtipo="AVP"
                label="Série A"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="SB"
                subtipo="AVP"
                label="Série B"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="SC"
                subtipo="AVP"
                label="Série C"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="SD"
                subtipo="AVP"
                label="Série D"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="SE"
                subtipo="AVP"
                label="Série E"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="SF"
                subtipo="AVP"
                label="Série F"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />{/*
              <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="SG"
                subtipo="AVP"
                label="Série G"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="SH"
                subtipo="AVP"
                label="Série H"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />*/}
            </div>
          </CSSTransition> 

          {/* Menu Iniciados Feminino - 1ºFase - AVL*/}

          <CSSTransition
                in={activeMenu === "NINIF_AVL"}
                timeout={500}
                classNames="menu-secondary"
                unmountOnExit
                onEnter={calcHeight}
          >
          <div className="menu">
          <DropdownItem 
              goToMenu="NINIF_1Fase" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
            <DropdownItem
              label="1ª Fase"
              goToMenu = "NINIF_AVL_1Fase"
              setActiveMenu={setActiveMenu}
            />
               <DropdownItem
              label="2ª Fase"
              goToMenu = "NINIF_AVL_2Fase"
              setActiveMenu={setActiveMenu}
            />
            </div>
          </CSSTransition>

          {/* Menu Iniciados Feminino - 1ªFase - AVL */}

          <CSSTransition
            in={activeMenu === "NINIF_AVL_1Fase"}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="NINIM_1Fase" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
               <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="A"
                subtipo="AVL"
                label="Série A"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="B"
                subtipo="AVL"
                label="Série B"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="C"
                subtipo="AVL"
                label="Série C"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="D"
                subtipo="AVL"
                label="Série D"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="E"
                subtipo="AVL"
                label="Série E"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
                 <DropdownItem
                campeonato="NINIF"
                fase="1"
                serie="AB"
                subtipo="AVL"
                label="Série AB"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
               <DropdownItem
                campeonato="NINIF"
                fase="1"
                serie="BB"
                subtipo="AVL"
                label="Série BB"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
            </div>
          </CSSTransition> 

          {/* Menu Iniciados Feminino - 1ªFase - AVL - 2º Fase*/}

          <CSSTransition
                in={activeMenu === "NINIF_AVL_2Fase"}
                timeout={500}
                classNames="menu-secondary"
                unmountOnExit
                onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="NINIF_AVL" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
               <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="SA"
                subtipo="AVL"
                label="Série A"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="SB"
                subtipo="AVL"
                label="Série B"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="SC"
                subtipo="AVL"
                label="Série C"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="SD"
                subtipo="AVL"
                label="Série D"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="SE"
                subtipo="AVL"
                label="Série E"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="SF"
                subtipo="AVL"
                label="Série F"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="DA"
                subtipo="AVL"
                label="Série AB"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="DB"
                subtipo="AVL"
                label="Série BB"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
            </div>
          </CSSTransition> 

    {/* Menu Iniciados Feminino - 1ºFase - AVB*/}

        <CSSTransition
            in={activeMenu === "NINIF_AVB"}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}
         >
          <div className="menu">
            <DropdownItem
              label="1ª Fase"
              goToMenu = "NINIF_AVB_1Fase"
              setActiveMenu={setActiveMenu}
            />
               <DropdownItem
              label="2ª Fase"
              goToMenu = "NINIF_AVB_2Fase"
              setActiveMenu={setActiveMenu}
            />
            </div>
          </CSSTransition>

          {/* Menu Iniciados Feminino - 1ªFase - AVB - 1ªFase*/}

          <CSSTransition
            in={activeMenu === "NINIF_AVB_1Fase"}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="NINIM_1Fase" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
               <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="A"
                subtipo="AVB"
                label="Série A"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              /> {/*
              <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="B"
                subtipo="AVB"
                label="Série B"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="PA"
                subtipo="AVB"
                label="Play-Off Apuramento 2ºFase"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />*/}
            </div>
          </CSSTransition> 

          {/* Menu Iniciados Feminino - 1ªFase - AVB - 2º Fase

          <CSSTransition
            in={activeMenu === "NINIF_AVB_2Fase"}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="NINIF_AVB" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
               <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="SA"
                subtipo="AVB"
                label="Série A"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="SB"
                subtipo="AVB"
                label="Série B"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
            </div>
          </CSSTransition> */}

          {/* Menu Iniciados Feminino - 1ªFase - AVAL*/}

          <CSSTransition
            in={activeMenu === "NINIF_AVAL"}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="NINIM_1Fase" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
               <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="A"
                subtipo="AVAL"
                label="Série A"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />{/*
              <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="B"
                subtipo="AVAL"
                label="Série Algarve"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />*/}
            </div>
          </CSSTransition> 

           {/* Menu Iniciados Feminino - 1ªFase - AVTM*/}

           <CSSTransition
            in={activeMenu === "NINIF_AVTM"}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="NINIM_1Fase" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
               <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="A"
                subtipo="AVTM"
                label="Série A"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NINIF"
                fase="1"
                serie="B"
                subtipo="AVTM"
                label="Série B"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
            </div>
          </CSSTransition> 

          {/* Menu Iniciados Feminino - 2ªFase */}

          <CSSTransition
            in={activeMenu === "NINIF_2Fase"}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="main" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
            <DropdownItem 
  campeonato="NINIF"
  fase="2"
  serie="SA"
  label="Série A"
  onSubmit="true"
  setActiveMenu={setActiveMenu}
  formAction={true}
/>
<DropdownItem 
  campeonato="NINIF"
  fase="2"
  serie="SB"
  label="Série B"
  onSubmit="true"
  setActiveMenu={setActiveMenu}
  formAction={true}
/>
<DropdownItem 
  campeonato="NINIF"
  fase="2"
  serie="SC"
  label="Série C"
  onSubmit="true"
  setActiveMenu={setActiveMenu}
  formAction={true}
/>
<DropdownItem 
  campeonato="NINIF"
  fase="2"
  serie="SD"
  label="Série D"
  onSubmit="true"
  setActiveMenu={setActiveMenu}
  formAction={true}
/>
            </div>
          </CSSTransition>

          {/* Menu Iniciados Feminino */}

          <CSSTransition
            in={activeMenu === "NINIF_Final"}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="main" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
            <DropdownItem
                campeonato="NINIF"
                fase="F"
                serie="F"
                label="Apuramento do Campeão Nacional"
                onSubmit="true"
              />
            </div>
          </CSSTransition>
        </div>
      )}
    </div>
  );
};

const DropdownItem = ({ campeonato, fase, serie, subtipo, label, goToMenu, setActiveMenu, onSubmit }) => {
  const buttonRef = React.useRef(null);
  
  const handleClick = () => {
    if (goToMenu) {
      setActiveMenu(goToMenu);
    } else if (onSubmit) {
      const selector = `#${campeonato}-${fase}-${serie}-${subtipo}`.replace(/[^a-zA-Z0-9-_]/g, '-');
      const form = document.querySelector(selector);
      if (form) {
        form.submit();
      }
    }
  };
  
  let actionUrl = "https://www.portugalvoleibol.com/classificacao/classificacoes_assoc";
  
  if (fase === "2" || fase === "F") {
    actionUrl = "https://www.portugalvoleibol.com/classificacao/classificacoes_react";
  }
  
  return (
    <div className="menu-item" onClick={handleClick}>
      {onSubmit ? (
        <form
          onSubmit={(e) => {
          }}
          action={actionUrl}
          method="POST"
        >
          <input type="hidden" name="campeonato" value={campeonato} />
          <input type="hidden" name="fase" value={fase} />
          <input type="hidden" name="serie" value={serie} />
          <input type="hidden" name="subtipo" value={subtipo} />
          <button type="submit" ref={buttonRef}>
            {label}
          </button>
        </form>
      ) : (
        <button type="button" ref={buttonRef}>
          {label}
        </button>
      )}
    </div>
  );
};

  export default MenuIniciados;